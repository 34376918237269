<template>
  <div class="container-top" v-if="order">
    <div class="pc container padding-top-60 position-relative">
      <div class="h5 main" style="padding-bottom:52px">결제하기</div>
      <div class="body0-bold main account-price-title">결제금액</div>
      <div class="item">
        <div class="subtitle5 sub2">상품</div>
        <div>
          <div class="flex-end">
            <div class="flex-align">
              <div class="body2">{{ order.order_product.product.name }}</div>
              <span class="price-indicator"></span>
              <div class="flex-align">
                <div class="body2-bold main">{{ order.order_product.product_price | currencyNum }}</div>
                <span class="body4 sub">원</span>
              </div>
            </div>
          </div>
          <div class="flex-end margin-top-8" v-if="order.isServiceUsePrice">
            <div class="body2">
              <span>서비스 운영 이용료</span><span class="primary">(월결제)</span>
            </div>
            <span class="price-indicator"></span>
            <div class="flex-align">
              <div class="body2-bold main">{{ order.service_use_price.price | currencyNum }}</div>
              <span class="body4 sub">원</span>
              <span class="body4 sub3">/월</span>
            </div>
          </div>
        </div>
      </div>

      <div class="item" v-if="isAddOption(order.order_product.order_product_option)">
        <div class="subtitle5 sub2">추가옵션</div>
        <div>
          <div class="flex-align"
               v-for="(option, option_idx) in order.order_product.order_product_option"
               :key="`option-${option_idx}`">
            <div class="flex-align" v-if="option.option_type===1">
              <div class="body2" v-html="optionName(option)"></div>
              <span class="price-indicator"></span>
              <div class="flex-align" v-if="optionPrice(option)>0">
                <div class="body2-bold main">{{ optionPrice(option) | currencyNum }}</div>
                <span class="body4 sub">원</span>
                <span class="body4 sub3" v-if="isSubscription(option, order.order_product.product)">/월</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="item">
        <div class="subtitle5 sub2">호스팅</div>
        <div>
          <div class="flex-align item-product">
            <div class="body2 sub3">{{ order.hosting.name }}</div>
            <span class="price-indicator"></span>
            <div class="price body2-bold main">{{ order.hosting.price|currencyNum }}</div><span class="body4 sub">원<span class="body4 sub3">/월</span></span>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="subtitle5 sub2">케어 서비스</div>
        <div>
          <div class="flex-align item-product">
            <div class="body2 sub3">{{ order.care_service.name }}</div>
            <span class="price-indicator"></span>
            <div class="price body2-bold main">{{ order.care_service.price|currencyNum }}</div><span class="body4 sub">원<span class="body4 sub3">/월</span></span>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="subtitle5 sub2">VAT 10% 별도</div>
        <div>
          <div class="item-product">
            <span class="price body2-bold main">{{ vat|currencyNum }}</span><span class="body4 sub">원</span>
          </div>
        </div>
      </div>

      <!-- 총 결제금액 -->
      <div style="margin-top:26px">
        <div class="flex-between">
          <div class="subtitle4 main">총 결제금액</div>
          <div>
            <span class="price h5 main">{{ totalPrice|currencyNum }}</span><span class="body2-medium sub">원</span>
          </div>
        </div>
        <div class="box-info">
          <img src="/static/icon/fi_alert-circle.svg" class="svg-primary" style="width:20px;height:20px;vertical-align: sub;margin-right:6px">
          첫 결제 후, 서비스 개설 완료 한달 뒤부터 <span class="body2-bold price">{{ subscribePrice|currencyNum }}</span>원(VAT 포함)이 자동결제됩니다.
        </div>
      </div>

      <!-- 결제 방법 -->
      <div style="margin-top:92px">
        <div class="account-price-method">
          <h5 class="body0-bold main">결제 방법</h5>
          <div class="sub3" style="margin-top:8px">매월 서비스 이용료 및 부가서비스 비용을 납부할 결제 방식입니다.</div>
        </div>
        <div style="margin:32px 0 56px 0">
          <billing-form ref="form"></billing-form>
        </div>
        <lp-check-box-array :value.sync="agree" :items="checkItem"></lp-check-box-array>
      </div>
      <div class="flex-center" style="margin-top:80px">
        <button class="button is-gray" style="width:242px;margin-right:8px" @click="clickPrev">이전</button>
        <button class="button is-primary" style="width:242px;margin-left:8px" @click="clickAccount">결제하기</button>
      </div>
    </div>
  </div>
</template>

<script>
  import LpCheckBoxArray from "../component/LpCheckBoxArray";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import OrderMixin from "../../mixins/OrderMixin";
  import BillingForm from "../module/BillingForm";
  export default {
    name: "SkinAccount",
    components: {BillingForm, LpCheckBoxArray},
    mixins: [
      UserAPIMixin,
      OrderMixin
    ],
    created() {
      this.init();
      this.sbRequest();

      window.addEventListener('message', e => {
        // console.log(e.data); // { result: 'close' }
        if(!e.data) return;
        if(e.data.result==='close') {
          this.visiblePG=false;
        }
      });
    },
    data() {
      return {
        isBillingPc: false,
        isBilling: false,
        agree: false,
        checkItem: [
          {
            label: '구매 정보 및 결제 내역을 확인했으며, 이에 동의합니다.',
            labelStyle: {
              color: '#4f4f4f',
              fontSize: '15px'
            }
          }
        ],
        order: undefined,
        visiblePG: false
      }
    },
    methods: {
      sbRequest() {
        if(this.$route.query.resultCode) {
         if(this.$route.query.resultCode === 'Success') {
           this.$router.push(`bill_done?reserveId=${this.$route.query.reserveId}&tempReceiptId=${this.$route.query.tempReceiptId}&result={"resultCode":"00"}`);
         } else {
           this.toast('네이버페이 정기결제 등록에 실패했습니다.');
         }
        }
        /*let result = JSON.parse(this.$route.query.result);
        let resultCode = result.resultCode;
        if (resultCode !== '00' && resultCode !== '0000') {
          this.toast('입력하신 카드정보가 잘못되었습니다. 다시 입력하시기 바랍니다.');
          return;
        }
        this.$router.push(`/bill_done?result=${JSON.stringify(result)}`);*/
      },
      init() {
        this.order = this.$store.getters.basket;
      },
      clickPrev() {
        this.$router.back();
      },
      validate() {
        return !(Object.keys(this.$refs.form.value).some(key => this.$refs.form.value[key] === ''));
      },
      clickAccount() {
        if(!this.agree) {
          this.toast('동의 후 진행해주세요');
          return;
        }
        this.order.total_price = this.totalPrice;
        this.order.subscribe_price = this.subscribePrice;
        this.order.service_type = this.order.type;

        let product_name = this.order.order_product.product.name;

        this.$store.commit('setBasket', this.order);

        if(this.$refs.form.value.pg_type === 'payple') {
          let param = this.$refs.form.value;
          param.service_key = this.order.key;

          if(this.$refs.form.selectedBill) {
            this.order.user_bill = this.$refs.form.selectedBill.id;
            this.$store.commit('setBasket', this.order);
            this.$router.push('bill_done?result={"payple":true, "resultCode":"00"}');
          } else {
            if(!this.validate()) {
              this.toast('카드 정보를 제대로 입력해주세요.');
              return;
            }

            this.setLoading('카드 등록중입니다');
            this.request.user.post('launchpack/card_bill', this.$refs.form.value).then(res=>{
              this.clearLoading();
              if(res.data.result === 'error') {
                if(res.data.message.indexOf('CDAU1002')>-1) {
                  this.toast(res.data.message.replace('[CDAU1002] ',''));
                } else {
                  this.toast('카드 정보가 잘못 입력되었습니다.');
                  Object.keys(this.$refs.form.err).forEach((key=>{
                    this.$refs.form.err[key].err = false;
                  }));
                  for(let item of [
                    { code: '3110', key: 'card_num' },
                    { code: '3115', key: 'pw' },
                    { code: '3119', key: 'ex' },
                    { code: '3131', key: 'birth' }
                  ]) {
                    if(res.data.message.indexOf(item.code)>-1) {
                      this.$refs.form.err[item.key].err = true;
                    }
                  }
                }
              } else {
                // 카드 등록 성공
                this.order.user_bill = res.data.bill_id;
                this.$store.commit('setBasket', this.order);
                this.$router.push('bill_done?result={"payple":true, "resultCode":"00"}');
              }
            }).catch(()=>{
              this.clearLoading();
            });
          }
        } else {
          // eslint-disable-next-line no-undef
          let oPay = Naver.Pay.create({
            payType: 'recurrent',
            mode: 'development',
            clientId: 'upYIvXN6qkneNe3sBFbt'
          })
          oPay.open({
            "actionType" : "NEW",
            "targetRecurrentId": "",
            "productCode": this.order.key+this.dayjs().format('YYYYMMDDHHmmss'),
            "productName": product_name,
            "totalPayAmount": this.order.total_price,
            "returnUrl": "https://launchpack.co.kr/service_account"
            //"returnUrl": "http://localhost:8080/service_account"
          });
        }

        /*this.request.user.get('launchpack/has_bill').then(res=>{
          if(res.status === 200) {
            // 등록된 빌링정보가 있는 경우
            if (res.data.result) {
              let result = {
                already: true,
                TotPrice: this.order.total_price,
                resultCode: '0000',
                CARD_Code: res.data.code,
                CARD_Num: res.data.num
              };
              this.$router.push(`/bill_done?result=${JSON.stringify(result)}`);
            } // 없는 경우 신규 등록 후 개설 진행
            else {
              if (this.isMobile) {
                this.isBilling = true;
                this.visiblePG = true;
              } else {
                this.isBillingPc = true;
                this.visiblePG = true;
              }
            }
          }
        });*/
      }
    },
    computed: {
      pluginPrice() {
        let price = 0;
        this.order.service.plugins.forEach(i => {
          price += i.price.price;
        });
        return price;
      },
      hostingPrice() {
        return this.order.hosting ? this.order.hosting.price : 0;
      },
      carePrice() {
        return this.order.care_service ? this.order.care_service.price : 0;
      },
      totalOrgPrice() {
        let price = this.hostingPrice + this.carePrice;
        if(this.order.isServiceUsePrice) {
          price += this.order.service_use_price.price;
        }
        //price += price/10;
        price += this.order.totalPrice;
        return price;
      },
      subscribePrice() {
        let price = this.hostingPrice + this.carePrice;
        //return price + (price / 10);
        return price;
      },
      totalPrice() {
        return this.totalOrgPrice + this.vat;
      },
      vat() {
        //return this.totalPrice * 1/11;
        return this.totalOrgPrice / 10;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .account-price-title
    border-top 1px solid $main
    border-bottom 1px solid $sub5
    padding 16px 0
  .account-price-method
    border-top 1px solid $main
    border-bottom 1px solid $gray2
    padding 16px 0
  .item
    padding 32px 0
    border-bottom 1px solid $gray2
    display flex
    justify-content space-between
  .item-product
    margin-bottom 8px
    text-align right
  .item-product:last-child
    margin-bottom 0
  .box-info
    background-color $gray4
    padding 24px
    border-radius 8px
    margin-top 8px

  .box-method
    border 1px solid $primary
    background-color $primary-light2
    color $primary
    text-align center
    width 130px
    height 40px
    line-height 40px
    border-radius 8px
    font-size 14px

  .price-indicator
    border 2px solid $gray1
    border-radius 50%
    margin 0 12px
</style>
